import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MovieResult, Person, PersonResult, SearchMultiResponse, TvResult } from 'moviedb-promise/dist/request-types';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { MovieDbService } from 'src/app/services/movie-db.service';

@Component({
  selector: 'app-movie-search',
  templateUrl: './movie-search.component.html',
  styleUrls: ['./movie-search.component.scss']
})
export class MovieSearchComponent implements OnInit {

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @Output() selected = new EventEmitter<any>();

  public showSpinner = false;
  public results?: Array<MovieResult | TvResult | PersonResult | any>;

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.results = [];
    }
  }

  constructor(
    private elementRef: ElementRef,
    public mdb: MovieDbService,
  ) { }

  ngOnInit(): void {
    this.initSearch();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.results && this.results.length > 0) {
      this.closeSearch();
    }
  }

  initSearch() {
    const keyup$ = fromEvent(this.searchInput.nativeElement, 'keyup');
    keyup$
      .pipe(
        map((i: any) => {
          this.showSpinner = true
          return i.currentTarget.value
        }),
        debounceTime(500)
      )
      .subscribe(el => {
        if (el && el.length > 0) {
          this.results = [];
          this.getTitle(el).then(() => this.showSpinner = false)
        } else {
          this.showSpinner = false
          this.results = [];
        }
      });
  }

  closeSearch() {
    this.searchInput.nativeElement.value = ''
    this.searchInput.nativeElement.dispatchEvent(new KeyboardEvent('keyup'));
    this.results = [];
  }

  async getTitle(q: string) {
    this.mdb.searchTitle(q).then(res => {
      this.results = [];
      this.results = res.results;
      this.results =  this.results?.filter((r:any) => r.media_type === 'movie' || r.media_type === 'tv')
    })
  }

  addItemToList(item: MovieResult | TvResult) {
    this.selected.emit(item);
    this.closeSearch();
  }


}
