<div class="position-relative">
  <div class="input-group my-4 search-input">
    <input
      #searchInput
      id="searchInput"
      type="text"
      class="form-control"
      placeholder="Add a new title"
      i18n-placeholder
      aria-label="Add a new title"
      aria-describedby="basic-addon2"
    />
    <div class="input-group-append">
      <span class="input-group-text" id="basic-addon2">
        <fa-icon
          *ngIf="showSpinner"
          [icon]="['fas', 'circle-notch']"
          [spin]="true"
        ></fa-icon>
        <fa-icon *ngIf="!showSpinner" [icon]="['fas', 'search']"></fa-icon>
      </span>
    </div>
  </div>

  <div class="results-box" *ngIf="results && results.length > 0">
    <div class="row" *ngFor="let item of results; last as isLast">
      <div class="col">
        <!-- <ng-container *ngFor="let item of results">
        <img
          *ngIf="item.poster_path"
          class="mr-2 mb-2 result-poster"
          [src]="mdb.getFullPosterPath(item.poster_path, '92')"
        />
      </ng-container> -->

        <div class="d-flex align-items-center align-self-center w-100">
          <img
            *ngIf="item.poster_path"
            loading="lazy"
            class="search-poster mr-3"
            [src]="mdb.getFullPosterPath(item.poster_path, '92')"
          />
          <div *ngIf="!item.poster_path" class="fallback-cover mr-3" class="ml-1" i18n>No image</div>
          <div class="w-100">
            <ng-container *ngIf="item.media_type === 'movie'">
              <h5>{{ item.title }} <small>(Movie)</small></h5>
              <p>{{ item.release_date }}</p>
            </ng-container>

            <ng-container *ngIf="item.media_type === 'tv'">
              <h5>{{ item.name }} <small>(TV Series)</small></h5>
              <p>{{ item.first_air_date }}</p>
            </ng-container>

            <button class="btn btn-link" (click)="addItemToList(item)">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon> <span class="ml-1" i18n>Add to list</span>
            </button>
          </div>
        </div>
        <hr *ngIf="!isLast"/>
      </div>
    </div>
  </div>
</div>
