import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EmailShareComponent } from './email-share/email-share.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListDiscoverWrapperComponent } from './list-discover/list-discover-wrapper/list-discover-wrapper.component';
import { ListDiscoverItemComponent } from './list-discover/list-discover-item/list-discover-item.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    EmailShareComponent,
    ListDiscoverWrapperComponent,
    ListDiscoverItemComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,RouterModule
  ],
  exports: [
    EmailShareComponent,
    ListDiscoverWrapperComponent,
    ListDiscoverItemComponent
  ]
})
export class SharedModule {
  
 }
