<div class="movie-item-wrapper mb-3 bg-white" *ngIf="!gridView">
  <div class="d-flex align-items-stretch">
    <div>
      <div class="poster mr-3">
        <img
          *ngIf="posterPath"
          src="{{ posterPath }}"
          class="c-pointer brightness-hover"
          lightbox
          loading="lazy"
          [fullImage]="{ path: posterPathLg }"
          imageMaxHeight="90%"
          imageMaxWidth="90%"
        />

        <!-- <a appLightbox [href]="posterPathLg" data-touch="true">
          <img [src]="posterPath" />
        </a> -->
      </div>
    </div>

    <div class="d-flex w-100 align-items-start flex-column p-3">
      <div class="d-flex w-100 justify-content-between align-items-start">
        <h5>
          {{
            movieDetail?.title
              ? movieDetail.title
              : showDetail?.name
              ? showDetail.name
              : ""
          }}
        </h5>
        <button
          class="btn btn-link text-danger p-0"
          *ngIf="editMode && showRemove"
          (click)="removeItem((movieDetail || showDetail).id!, type)"
        >
          <fa-icon [icon]="['far', 'times-circle']"></fa-icon>
        </button>
      </div>

      <p class="mb-2 text-secondary" *ngIf="movieDetail || showDetail">
        {{ releaseDate.length > 0 ? releaseDate + " • " : "" }}

        <span
          *ngFor="
            let genre of (movieDetail || showDetail).genres;
            last as isLast
          "
          >{{ genre.name + (!isLast ? ", " : "") }}</span
        >

        <ng-container *ngIf="(movieDetail || showDetail).runtime">{{
          " • " + movieDetail.runtime + "m"
        }}</ng-container>
      </p>

      <p
        class="description-text"
        [class.cut]="textCollapsed"
        (click)="textToggle()"
        *ngIf="movieDetail || showDetail"
      >
        {{ (movieDetail || showDetail).overview }}
      </p>
      <div class="mb-3">
        <a
          class="btn btn-link btn-sm mr-2"
          *ngIf="trailerUrl"
          href="{{ trailerUrl }}"
          appLightbox
        >
          <fa-icon [icon]="['fas', 'play-circle']"></fa-icon> Trailer</a
        >
        <!-- <button
          class="btn btn-link text-danger btn-sm"
          *ngIf="editMode && showRemove"
          (click)="removeItem((movieDetail || showDetail).id!, type)"
        >
          <fa-icon [icon]="['far', 'times-circle']"></fa-icon> Remove
        </button> -->
      </div>

      <div class="mt-auto" *ngIf="regionWatchProvider">
        <p *ngIf="regionWatchProvider.flatrate">
          <span class="provider-title">Flatrate: </span>
          <a [href]="regionWatchProvider.link" target="_blank">
            <img
              *ngFor="let p of regionWatchProvider.flatrate"
              [src]="'https://image.tmdb.org/t/p/w45' + p.logo_path"
              class="provider-logo"
            />
          </a>
        </p>

        <button
          *ngIf="
            !showProviders &&
            (regionWatchProvider.flatrate_and_buy ||
              regionWatchProvider.rent ||
              regionWatchProvider.buy) &&
            regionWatchProvider.flatrate
          "
          class="btn btn-outline-secondary btn-sm d-block"
          (click)="showProviders = !showProviders"
        >
          <span i18n>Show buy options</span>
          <fa-icon [icon]="['fas', 'angle-down']"></fa-icon>
        </button>

        <ng-container *ngIf="showProviders || !regionWatchProvider.flatrate">
          <p *ngIf="regionWatchProvider.flatrate_and_buy">
            <span class="provider-title" i18n>Flatrate and Buy: </span>

            <a [href]="regionWatchProvider.link" target="_blank">
              <img
                *ngFor="let p of regionWatchProvider.flatrate_and_buy"
                [src]="'https://image.tmdb.org/t/p/w45' + p.logo_path"
                width="35"
                height="35"
                class="provider-logo"
              />
            </a>
          </p>

          <p *ngIf="regionWatchProvider.rent">
            <span class="provider-title">Rent: </span>

            <a [href]="regionWatchProvider.link" target="_blank">
              <img
                *ngFor="let p of regionWatchProvider.rent"
                [src]="'https://image.tmdb.org/t/p/w45' + p.logo_path"
                width="35"
                height="35"
                class="provider-logo"
              />
            </a>
          </p>
          <p *ngIf="regionWatchProvider.buy">
            <span class="provider-title">Buy: </span>

            <a [href]="regionWatchProvider.link" target="_blank">
              <img
                *ngFor="let p of regionWatchProvider.buy"
                [src]="'https://image.tmdb.org/t/p/w45' + p.logo_path"
                width="35"
                height="35"
                class="provider-logo"
              />
            </a>
          </p>
        </ng-container>

        <p>
          <small
            ><span i18n>Stream provider information provided by</span>
            <a class="ml-1" href="https://www.justwatch.com/" target="_blank"
              >JustWatch</a
            >.</small
          >
        </p>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="gridView">
  <img
    (click)="openDetailInModal(template)"
    class="p-1 grid-view c-pointer brightness-hover"
    *ngIf="movieDetail || showDetail"
    src="{{ posterPath ? posterPath : 'assets/fallback_cover.jpg' }}"
    loading="lazy"
  />
</ng-container>

<ng-template #template>
  <div class="modal-header">
    <div>
      <h4 class="modal-title">
        {{ movieDetail?.title ? movieDetail.title : showDetail.name }}
      </h4>

      <p class="mb-2 text-secondary w-100">
        {{ releaseDate.length > 0 ? releaseDate + " • " : "" }}

        <span
          *ngFor="
            let genre of (movieDetail || showDetail).genres;
            last as isLast
          "
          >{{ genre.name + (!isLast ? ", " : "") }}</span
        >

        <ng-container *ngIf="(movieDetail || showDetail).runtime">{{
          " • " + movieDetail.runtime + "m"
        }}</ng-container>
      </p>
    </div>

    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="detailModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="movieDetail || showDetail">
      <div class="d-flex align-items-stretch">
        <div class="d-flex align-self-center align-items-start flex-column">
          <p class="description-text">
            {{ (movieDetail || showDetail).overview }}
          </p>

          <!-- <img
            [src]="
              'https://image.tmdb.org/t/p/w780/' + movieDetail.backdrop_path
            "
            width="100%"
          /> -->
          <div class="mb-2">
            <a
              class="btn btn-link btn-sm mr-2"
              *ngIf="trailerUrl"
              href="{{ trailerUrl }}"
              appLightbox
            >
              <fa-icon [icon]="['fas', 'play-circle']"></fa-icon> Trailer</a
            >
            <button
              class="btn btn-link btn-sm text-danger"
              *ngIf="editMode && showRemove"
              (click)="
                detailModalRef.hide();
                removeItem((movieDetail || showDetail).id!, type)
              "
            >
              <fa-icon [icon]="['far', 'times-circle']"></fa-icon> Remove from
              list
            </button>
          </div>

          <div class="mt-auto" *ngIf="regionWatchProvider">
            <p *ngIf="regionWatchProvider.flatrate">
              <span class="provider-title">Flatrate: </span><br />
              <a [href]="regionWatchProvider.link" target="_blank">
                <img
                  *ngFor="let p of regionWatchProvider.flatrate"
                  [src]="'https://image.tmdb.org/t/p/w45' + p.logo_path"
                  class="provider-logo"
                />
              </a>
            </p>

            <p *ngIf="regionWatchProvider.flatrate_and_buy">
              <span class="provider-title">Flatrate and buy: </span><br />

              <a [href]="regionWatchProvider.link" target="_blank">
                <img
                  *ngFor="let p of regionWatchProvider.flatrate_and_buy"
                  [src]="'https://image.tmdb.org/t/p/w45' + p.logo_path"
                  width="35"
                  height="35"
                  class="provider-logo"
                />
              </a>
            </p>

            <p *ngIf="regionWatchProvider.rent">
              <span class="provider-title">Rent: </span><br />

              <a [href]="regionWatchProvider.link" target="_blank">
                <img
                  *ngFor="let p of regionWatchProvider.rent"
                  [src]="'https://image.tmdb.org/t/p/w45' + p.logo_path"
                  width="35"
                  height="35"
                  class="provider-logo"
                />
              </a>
            </p>
            <p *ngIf="regionWatchProvider.buy">
              <span class="provider-title">Buy: </span><br />

              <a [href]="regionWatchProvider.link" target="_blank">
                <img
                  *ngFor="let p of regionWatchProvider.buy"
                  [src]="'https://image.tmdb.org/t/p/w45' + p.logo_path"
                  width="35"
                  height="35"
                  class="provider-logo"
                />
              </a>
            </p>

            <p>
              <small
                >Stream provider information provided by
                <a href="https://www.justwatch.com/" target="_blank"
                  >JustWatch</a
                >.</small
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
