<div class="container my-5">
  <div class="row">
    <div class="col">

      <h1>Datenschutzerklärung</h1>
      <p>
        Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
        EU-Datenschutzgrundverordnung (DSGVO), ist:
      </p>
      <p>
        Fabian Sagodi<br />
        Kesselgraben 2<br />
        90427 Nürnberg <br />
        <a href="mailto:contact@watch-lists.com">contact@watch-lists.com</a>
      </p>
      <!-- <h2>Ihre Betroffenenrechte</h2>
      <p>
        Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
        können Sie jederzeit folgende Rechte ausüben:
      </p>
      <ul>
        <li>
          Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
          (Art. 15 DSGVO),
        </li>
        <li>
          Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
        </li>
        <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
        <li>
          Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
          gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
        </li>
        <li>
          Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
          und
        </li>
        <li>
          Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
          haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
        </li>
      </ul>
      <p>
        Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
        jederzeit mit Wirkung für die Zukunft widerrufen.
      </p>
      <p>
        Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
        wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
        Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
        Behörde.
      </p>
      <p>
        Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
        Anschrift finden Sie unter:
        <a
          href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
          target="_blank"
          rel="noopener nofollow"
          >https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a
        >.
      </p>
      <p></p> -->
      <strong>Erfassung allgemeiner Informationen beim Besuch unserer Website</strong><br>
      <strong>Art und Zweck der Verarbeitung:</strong><br>
      <p>
        Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
        registrieren oder anderweitig Informationen übermitteln, werden
        automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
        (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das
        verwendete Betriebssystem, den Domainnamen Ihres
        Internet-Service-Providers, Ihre IP-Adresse und ähnliches.
      </p>
      <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
      <ul>
        <li>
          Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
        </li>
        <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
        <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
        <li>zur Optimierung unserer Website.</li>
      </ul>
      <p>
        Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
        ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert
        statistisch ausgewertet, um unseren Internetauftritt und die
        dahinterstehende Technik zu optimieren.
      </p>
      <strong>Rechtsgrundlage und berechtigtes Interesse:</strong><br>
      <p>
        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
        unseres berechtigten Interesses an der Verbesserung der Stabilität und
        Funktionalität unserer Website.
      </p>
      <strong>Empfänger:</strong> <br>
      <p>
        Empfänger der Daten sind ggf. technische Dienstleister, die für den
        Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig
        werden.
      </p>
      <strong>Speicherdauer:</strong><br>
      <p>
        Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht
        mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung
        der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung
        beendet ist.
      </p>
      <p>
        Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens
        14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In
        diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine
        Zuordnung des aufrufenden Clients nicht mehr möglich ist.
      </p>
      <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong><br>
      <p>
        Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
        gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist
        jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
        gewährleistet. Zudem können einzelne Dienste und Services nicht
        verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch
        ausgeschlossen.
      </p>
      <!-- <h2>Eingebettete YouTube-Videos</h2>
      <p>
        Auf unserer Website betten wir YouTube-Videos ein. Betreiber der
        entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno,
        CA 94066, USA (nachfolgend „YouTube“). Die YouTube, LLC ist einer
        Tochtergesellschaft der Google LLC, 1600 Amphitheatre Pkwy, Mountain
        View, CA 94043, USA (nachfolgend „Google“). Wenn Sie eine Seite mit dem
        YouTube-Plugin besuchen, wird eine Verbindung zu Servern von YouTube
        hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten Sie besuchen.
        Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann YouTube Ihr
        Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem
        Sie sich vorher aus Ihrem YouTube-Account ausloggen.
      </p>
      <p>
        Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die
        Hinweise über das Nutzerverhalten sammeln.
      </p>
      <p>
        Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer
        Verarbeitung durch YouTube erhalten Sie in den Datenschutzerklärungen
        des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren
        diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer
        Privatsphäre (<a href="https://policies.google.com/privacy"
          >https://policies.google.com/privacy</a
        >).
      </p> -->
      <!-- <h3>Widerruf der Einwilligung:</h3>
      <p>
        Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out
        oder ein Blockieren der Datenübertragung angeboten. Wenn Sie eine
        Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern wollen,
        widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die
        entsprechende Cookie-Kategorie oder alle technisch nicht notwendigen
        Cookies und Datenübertragungen. In diesem Fall können Sie unsere Website
        jedoch ggfs. nicht oder nur eingeschränkt nutzen.
      </p>
      <p></p> -->
      <strong>SSL-Verschlüsselung</strong><br>
      <p>
        Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
        wir dem aktuellen Stand der Technik entsprechende
        Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
      </p>

      <!-- <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
      <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
      <p>
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
        personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
        (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt,
        Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung
        gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
      </p>
      <p>
        Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht
        mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe
        für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
        Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
        Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>
      <h3>Empfänger eines Widerspruchs</h3>
      <p>
        Fabian Sagodi<br />
        Kesselgraben 2<br />
        90427 Nürnberg <br />
        <a href="mailto:contact@watch-lists.com">contact@watch-lists.com</a>
      </p>
      <hr /> -->
      <strong>Änderung unserer Datenschutzbestimmungen</strong><br>
      <p>
        Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
        stets den aktuellen rechtlichen Anforderungen entspricht oder um
        Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
        z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
        dann die neue Datenschutzerklärung.
      </p>
      <!-- <h2>Fragen an den Datenschutzbeauftragten</h2>
      <p>
        Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
        E-Mail oder wenden Sie sich direkt an die für den Datenschutz
        verantwortliche Person in unserer Organisation:
      </p>
      <p>
        Fabian Sagodi<br />
        Kesselgraben 2<br />
        90427 Nürnberg <br />
        <a href="mailto:contact@watch-lists.com">contact@watch-lists.com</a>
      </p> -->
    </div>
  </div>
</div>
