import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-share-info-modal',
  templateUrl: './publish-info-modal.component.html',
  styleUrls: ['./publish-info-modal.component.scss']
})
export class PublishInfoModalComponent implements OnInit {
  @Output() publishEvent = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  publishEmit() {
    this.publishEvent.emit();
  }

}
