import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-android-banner',
  templateUrl: './android-banner.component.html',
  styleUrls: ['./android-banner.component.scss']
})
export class AndroidBannerComponent implements OnInit {
  public isAndroid = false;
  public hideAppBar = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
    this.checkPlattform();
  }

  setLink() {   
    // build url and set it as href
    if (isPlatformBrowser(this.platformId)) {
      const url = window.location.href + "#Intent;scheme=https;package=com.watch_lists.app;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.watch_lists.app;end";
      // https://watch-lists.com/#Intent;scheme=https;package=com.watch_lists.app;end  
      var refLink = document.getElementById('refLink'); refLink?.setAttribute('href', url);
      refLink?.click();
    }
  }

  checkPlattform() {
    if (isPlatformBrowser(this.platformId)) {
      // get device
      var ua = navigator.userAgent.toLowerCase();
      this.isAndroid = ua.indexOf("android") > -1;
      // read storage
      let hideStatus = window.sessionStorage.getItem('hideAppBar');
      if (hideStatus == '1') {
        this.hideAppBar = true;
      }
    }
  }

  hideBanner() {
    window.sessionStorage.setItem('hideAppBar', '1');
    this.hideAppBar = true;
  }

}
