<div class="wrapper d-flex flex-column min-vh-100">
  <!-- Navbar -->
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <a class="navbar-brand" href [routerLink]="'/'"
      ><span class="mr-1" style="color: #00d0da"
        ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon></span
      >Watchlists</a
    >

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown" dropdown>
        <a
          class="nav-link dropdown-toggle text-uppercase"
          href
          id="region-dropdown-link"
          dropdownToggle
          (click)="(false)"
        >
          {{ langObs | async }}</a
        >
        <ul
          id="region-dropdown"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="basic-link"
        >
          <li *ngFor="let lang of availableLanguages">
            <button class="dropdown-item" (click)="setLanguage(lang)">
              {{ lang | uppercase }}
            </button>
          </li>
        </ul>
      </li>
      <!--  -->

      <li class="nav-item active ml-3 d-none d-sm-block">
        <a
          class="btn btn-sm btn-primary nav-link"
          href
          [routerLink]="'/new'"
          i18n
          >Create new list</a
        >
      </li>
    </ul>
  </nav>

  <div class="flex-grow-1">
    <router-outlet></router-outlet>
  </div>

  <!-- Footer -->
  <footer>
    <div class="container-fluid bg-dark footer-container">
      <div class="container pt-3 pb-4">
        <div class="row">
          <div class="col-12 col-md-6">
            <p>
              <span class="font-weight-bold">Watchlists</span><br />
              <small
                ><a href [routerLink]="'/imprint'" i18n>Imprint</a> |
                <a href [routerLink]="'/privacy'" i18n>Privacy</a></small
              >
            </p>
          </div>
          <div
            class="
              col-12 col-md-6
              text-md-right
              d-flex
              flex-column
              justify-content-end
            "
          >
            <p class="mt-auto">
              <a href="https://www.themoviedb.org/" target="_blank">
                <img
                  src="/assets/tmdb.svg"
                  title="The Movie Database"
                  height="15px"
                  width="auto"
                  [alt]="'TMDb Logo'"
                /> </a
              ><br />
              <small class="mb-0" i18n
                >Watchlists uses the TMDb API but is not endorsed or certified
                by TMDb.</small
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
