import { Component, EventEmitter, OnInit, Output,TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-list-edit-modal',
  templateUrl: './list-edit-modal.component.html',
  styleUrls: ['./list-edit-modal.component.scss']
})
export class ListEditModalComponent implements OnInit {
  public title: string;
  public description: string;
  public deleteModalRef: BsModalRef;

  @Output() saveEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();

  listEditForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
  });



  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.listEditForm.setValue({
      name: this.title,
      description: this.description
    })
  }

  save() {
    const data =  { title: this.listEditForm.get('name')?.value, description:  this.listEditForm.get('description')?.value, }
    this.saveEvent.emit(
      data
    )
  }
  delete() {
    this.deleteEvent.emit();
    this.deleteModalRef?.hide();
  }

  showDeleteModal(template: TemplateRef<any>) {
    this.deleteModalRef = this.modalService.show(template);
  }

}