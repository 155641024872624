<div class="modal-header">
  <h4 class="modal-title pull-left" i18n>Publish your watchlist</h4>
</div>
<div class="modal-body">
  <div class="text-center mb-3">
    <img src="../../../assets/share_3.svg" height="200px" />
  </div>
  <p i18n>You are going to publish your list to the world.</p>
 
  <div class="alert alert-primary" role="alert" i18n>
    You can always manage your list with your private link when its published.
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()" i18n>
    Cancel
  </button>
  <button type="button" class="btn btn-success" (click)="publishEmit()" i18n>
    Publish now!
  </button>
</div>
