import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { MovieResponse, ShowResponse, VideosResponse, WatchProviderResponse } from 'moviedb-promise/dist/request-types';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { MovieDbService } from 'src/app/services/movie-db.service';
import { SeoService } from 'src/app/services/seo.service';



@Component({
  selector: 'app-movie-item',
  templateUrl: './movie-item.component.html',
  styleUrls: ['./movie-item.component.scss']
})
export class MovieItemComponent implements OnInit, OnDestroy {

  @Input() id: number;
  @Input() type: string;
  @Input() editMode = false;
  @Input() firstElement = false
  @Input() showRemove = false
  @Input() gridView = false;

  @Output() backdrop_path = new EventEmitter<any>();
  @Output() removeEvent = new EventEmitter<any>();

  public movieDetail: MovieResponse;
  public showDetail: ShowResponse;
  public videosResponse: VideosResponse;
  public watchProviderResponse: WatchProviderResponse;
  public regionWatchProvider: any;

  public posterPath: string;
  public posterPathLg: string;
  public trailerUrl: string;
  public releaseDate: string;

  public showProviders = false;
  public textCollapsed = true;

  public regionSubscription: Subscription;
  public languageSubscription: Subscription;


  public detailModalRef: BsModalRef;

  constructor(private seoService: SeoService,
    private modalService: BsModalService,
    private mdb: MovieDbService) { }

  ngOnInit(): void {
    this.regionSubscription = this.mdb.region$.subscribe(r => {
      this.getDetails(r);

    })
    this.languageSubscription = this.mdb.language$.subscribe(r => this.getDetails(this.mdb.region.value))

  }

  ngOnDestroy() {
    this.regionSubscription.unsubscribe()
    this.languageSubscription.unsubscribe()
  }


  getDetails(region: string) {

    if (this.type === 'movie') {
      this.mdb.getMovieObs(this.id).subscribe(res => {
        this.videosResponse = res.videos;
        this.watchProviderResponse = res['watch/providers'];

        this.regionWatchProvider = Object(this.watchProviderResponse.results)[region];

        this.movieDetail = res;

        if (this.firstElement && this.movieDetail.backdrop_path) {
          this.seoService.setOgImage(this.movieDetail.backdrop_path)
        }

        if (this.movieDetail.poster_path) {
          this.posterPath = this.mdb.getFullPosterPath(this.movieDetail.poster_path);
          this.posterPathLg = this.mdb.getFullPosterPath(this.movieDetail.poster_path, '780');
        }
        this.backdrop_path.emit(this.movieDetail.backdrop_path)
        this.releaseDate = this.movieDetail.release_date ?? '';


        if (this.videosResponse.results?.[0]) {
          this.trailerUrl = "https://www.youtube-nocookie.com/embed/" + this.getTrailerUrl(this.videosResponse.results);
        }
      })
    } else if (this.type === 'tv') {
      this.mdb.getShowObs(this.id).subscribe(res => {
        this.videosResponse = res.videos
        this.watchProviderResponse = res['watch/providers'];
        this.regionWatchProvider = Object(this.watchProviderResponse.results)[region];
        this.showDetail = res;

        if (this.firstElement && this.showDetail.backdrop_path) {
          this.seoService.setOgImage(this.showDetail.backdrop_path)
         }

         this.releaseDate = this.showDetail.first_air_date ?? '';

        if (this.showDetail.poster_path) {
          this.posterPath = this.mdb.getFullPosterPath(this.showDetail.poster_path);
          this.posterPathLg = this.mdb.getFullPosterPath(this.showDetail.poster_path, '780');
        }
        this.backdrop_path.emit(this.showDetail.backdrop_path)
        if (this.videosResponse.results?.[0]) {
          this.trailerUrl = "https://www.youtube-nocookie.com/embed/" + this.getTrailerUrl(this.videosResponse.results);
        }
      })

    }


  }

  getTrailerUrl(results: any[]): String {
    var bestYoutubeVideo = results.find(
      (v) =>
        v['type'] == 'Trailer' &&
        v['official'] &&
        v['site'] == 'YouTube') ??
      results.find(
        (v) => v['type'] == 'Trailer' && v['site'] == 'YouTube') ??
      results.find((v) => v['site'] == 'YouTube');

    return bestYoutubeVideo['key'] ?? '';
  }

  removeItem(id: number, media_type: string) {
    this.removeEvent.emit({ id, media_type });
  }

  textToggle() {
    this.textCollapsed = !this.textCollapsed;
  }

  collapseDetail() {
    this.gridView = !this.gridView;
  }


  openDetailInModal(template: TemplateRef<any>) {
    this.detailModalRef = this.modalService.show(template)

    this.detailModalRef.setClass('modal-lg')
  }

}
