<div class="row">
  <div class="col text-center">
    <h2 i18n>Discover Watchlists</h2>

    <div class="d-flex flex-wrap justify-content-center w-100 mt-5">
      <app-list-discover-item [listId]="list1"> </app-list-discover-item>
      <app-list-discover-item [listId]="list2" class="mx-2"> </app-list-discover-item>
      <app-list-discover-item [listId]="list3"> </app-list-discover-item>
    </div>
  </div>
</div>
