<div class="modal-header">
  <h4 class="modal-title pull-left" i18n>Share the watchlist</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label for="shareInput" i18n>Public link (for sharing):</label>
  <div class="input-group">
    <input
      id="shareInput"
      type="text"
      class="form-control"
      [value]="shareUrl"
      placeholder="Recipient's username"
      readonly
    />
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary"
        ngxClipboard
        [cbContent]="shareUrl"
        tooltip="Copy URL"
        i18n-tooltip
        #shareIcon="bs-tooltip"
      >
        <fa-icon [icon]="['fas', 'clipboard']"></fa-icon><span class="ml-1" i18n>Copy</span>
      </button>
    </div>
  </div>
  <div class="text-center">
    <qr-code
      value="{{ shareUrl }}"
      size="200"
      errorCorrectionLevel="M"
    ></qr-code>
  </div>
  <hr *ngIf="editUrl" />
  <div *ngIf="editUrl">
    <label for="editInput" i18n>Private link (to edit your list):</label>
    <div class="input-group">
      <input
        id="editInput"
        type="text"
        class="form-control"
        [value]="editUrl"
        placeholder="Recipient's username"
        readonly
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          ngxClipboard
          [cbContent]="editUrl"
          tooltip="Copy URL"
          i18n-tooltip
          #shareIcon="bs-tooltip"
        >
          <fa-icon [icon]="['fas', 'clipboard']"></fa-icon><span class="ml-1" i18n>Copy</span>
        </button>
      </div>
    </div>

    <div class="text-center">
      <qr-code
        class="mb-2"
        value="{{ editUrl }}"
        size="200"
        errorCorrectionLevel="M"
      ></qr-code>
    </div>

    <hr />

    <label i18n>Send links to your email address</label>
    <app-email-share
      [id]="id"
      [cred]="cred"
      [title]="title"
      [privateUrl]="editUrl"
      [publicUrl]="shareUrl"
    ></app-email-share>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()" i18n>
    Close
  </button>
</div>
