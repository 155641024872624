import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ApiServiceService } from 'src/app/services/api-service.service';

@Component({
  selector: 'app-email-share',
  templateUrl: './email-share.component.html',
  styleUrls: ['./email-share.component.scss']
})
export class EmailShareComponent implements OnInit {
  @Input() id: string;
  @Input() publicUrl: string;
  @Input() privateUrl: string;
  @Input() cred: string | null;
  @Input() title: string;

  public sendSuccess = false;

  emailForm = this.formBuilder.group({
    email: new UntypedFormControl('', [Validators.required, Validators.email])
  });
  constructor(
    private formBuilder: UntypedFormBuilder, private apiService: ApiServiceService) { }

  ngOnInit(): void {
  }
  submitEmail() {
    this.sendSuccess = true;
    const reciever = this.emailForm.get('email')?.value;
    this.apiService.sendLinksByMail(
      this.id, 
      this.cred, 
      this.publicUrl, 
      this.privateUrl, 
      reciever, 
      this.title).subscribe(e => {
        this.sendSuccess = true;
      })
  }

  resetForm() {
    this.emailForm.reset()
    this.sendSuccess = false;
  }
}
