import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MovieItemComponent } from './components/movie-item/movie-item.component';
import { MovieListComponent } from './components/movie-list/movie-list.component';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FormsModule } from '@angular/forms';
import { MovieSearchComponent } from './components/movie-search/movie-search.component';
import { ListEditModalComponent } from './components/list-edit-modal/list-edit-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LightboxDirective } from './directives/lightbox.directive';
import { NewListComponent } from './components/new-list/new-list.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ClipboardModule } from 'ngx-clipboard';
import { ShareModalComponent } from './components/share-modal/share-modal.component';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { PrivacyComponent } from './components/content/privacy/privacy.component';
import { ImprintComponent } from './components/content/imprint/imprint.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { SharedModule } from './modules/shared/shared.module';
import { PublishInfoModalComponent } from './components/publish-info-modal/publish-info-modal.component';
import { QrCodeModule } from 'ng-qrcode';
import { AndroidBannerComponent } from './components/android-banner/android-banner.component';

@NgModule({
  declarations: [
    AppComponent,
    MovieItemComponent,
    MovieListComponent,
    WrapperComponent,
    MovieSearchComponent,
    ListEditModalComponent,
    LightboxDirective,
    NewListComponent,
    ShareModalComponent,
    PrivacyComponent,
    ImprintComponent,
    PublishInfoModalComponent,
    AndroidBannerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'watchlists' }),
    TransferHttpCacheModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    ClipboardModule,
    CrystalLightboxModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    SharedModule,
    QrCodeModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
