import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {

  public shareUrl: string;
  public editUrl: string;
  public id: string;
  public cred: string;
  public title: string;


  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.shareUrl = `https://watch-lists.com/list/${this.id}`;
    if (this.cred) {
      this.editUrl = `https://watch-lists.com/list/${this.id}/${this.cred}`;
    }
  }



}
