import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  verify(obj: any): Observable<any> {
    return this.http.post(this.apiUrl + 'watchlist/verify', obj);
  }
  // createCred(id: string) {
  //   const obj = {
  //     "listId": id
  //   }
  //   return this.http.post(this.apiUrl + 'cred/create.php', obj)
  // }

  createList(list: any) {
    return this.http.post(this.apiUrl + 'watchlist', list)
  }

  getList(id: any): Observable<MovieList> {
    return this.http.get<MovieList>(this.apiUrl + `watchlist/${id}`);
  }

  updateList(list: any, id: any) {
    return this.http.put(this.apiUrl + `watchlist/${id}`, list);

  }

  deleteList(cred: any, id: any) {
    return this.http.delete(this.apiUrl + `watchlist/${id}/${cred['cred']}`);
  }

  sendLinksByMail(id: any, cred: any, publicUrl: string, privateUrl: string, reciever: string, title: string) {
    const data = {
      "id": id,
      "cred": cred,
      "publicUrl": publicUrl,
      "privateUrl": privateUrl,
      "reciever": reciever,
      "title": title
    }
    return this.http.post(this.apiUrl + 'watchlist/mail', data);

  }

  movieListToUpdate(movieList: MovieList, cred: string): MovieListUpdate {
    return {
      name: movieList.name,
      description: movieList.description,
      items: [...movieList.items],
      cred: cred
    }
  }
}
