import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


export interface PageMetadata {
  title: string;
  // image will be added later
  description: string;
}

const defaultMetadata: PageMetadata = {
  title: 'Watchlists: Sharing lists of your favourite movies and series.',
  description: 'Create and share lists of your favourite movies and series. For free and without registration!',
}


@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private titleSvc: Title,
    private metaSvc: Meta) { }

  updateTitle(title: string) {
    this.titleSvc.setTitle(title);
  }

  updateDescription(content: string) {
    this.metaSvc.updateTag({ name: 'description', content })
  }

  createCanonicalLink(url: string) {
    let link: HTMLLinkElement =
      this.dom.createElement('link');

    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    this.dom.head.appendChild(link);
  }

  setNoFollow() {
    this.metaSvc.updateTag({name:'robots', content:'noindex, nofollow'}, "name='robots'");
  }


  setMeta(title?: string, description?: string, canonical?: string, previewImageId?: string) {

    this.titleSvc.setTitle(title ? title : defaultMetadata.title);
    this.metaSvc.updateTag({ property: 'og:title', content: title ? title : defaultMetadata.title });


    this.metaSvc.updateTag({ name: 'description', content: description ? description : defaultMetadata.description });
    this.metaSvc.updateTag({ property: 'og:description', content: description ? description : defaultMetadata.description });

    this.metaSvc.updateTag({ property: 'og:type', content: 'website' });

    if(previewImageId){      
      this.metaSvc.updateTag({ property: 'og:image', content: "https://image.tmdb.org/t/p/w1280" + previewImageId });
      this.metaSvc.updateTag({ property: 'og:image', content: "https://image.tmdb.org/t/p/w780" + previewImageId });
    }

    if (canonical) {
      this.createCanonicalLink(canonical);
      this.metaSvc.updateTag({ property: 'og:url', content: canonical });
    }
  }

  setOgImage(previewImageId: string) {      
    this.metaSvc.updateTag({ property: 'og:image', content: "https://image.tmdb.org/t/p/w1280" + previewImageId });
    this.metaSvc.updateTag({ property: 'og:image', content: "https://image.tmdb.org/t/p/w780" + previewImageId });

  }

  resetMeta(){
    this.titleSvc.setTitle("Watchlist: "+  defaultMetadata.title);    
    this.metaSvc.updateTag({ property: 'og:title', content:defaultMetadata.title });

    this.updateDescription(defaultMetadata.description);
    this.metaSvc.updateTag({ property: 'og:description', content:defaultMetadata.description });

    this.metaSvc.removeTag("property='og:url'");   
    this.metaSvc.removeTag("property='og:image'");  

  }



}


