<form [formGroup]="emailForm" (ngSubmit)="submitEmail()" [class.d-none]="sendSuccess">
    <div class="input-group">
      <input
        required
        type="email"
        id="email"
        formControlName="email"
        class="form-control"
        placeholder="Email"
        aria-label="Email"
        
      />
      <div class="input-group-append">
        <button type="submit" class="btn btn-success" [disabled]="emailForm.invalid" i18n>Submit</button>
      </div>

    </div>
    <small class="text-danger" *ngIf="emailForm.invalid  && (emailForm.dirty || emailForm.touched)"  i18n>A valid email is required.</small>

  </form>

  <div class="alert alert-success" *ngIf="sendSuccess">
    <fa-icon [icon]="['far', 'check-circle']"></fa-icon> <span class="ml-1" i18n>An email to {{this.emailForm.get('email')?.value + ' '}}has been sent!</span> <span class="text-primary c-pointer ml-1" (click)="resetForm()" i18n>Send another email</span>
  </div>