import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { CountryCode } from 'src/app/models/countryCodes';
import { MovieDbService } from 'src/app/services/movie-db.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {

  public selectedRegion: CountryCode;
  public regionOptions: Array<string>


  regionObs: Observable<CountryCode>
  langObs: Observable<String>;


  public availableLanguages = ['de', 'en'];
  constructor(
    private mdb: MovieDbService,
    private router: Router,
  ) { }
  ngOnInit(): void {

    this.langObs = this.mdb.language$
  }


  setLanguage(lang: string) {
    window.location.replace(document.location.origin + '/' + lang + this.router.url)
  }
}

