import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
// import 'node_modules/iframe-lightbox/js/iframe-lightbox.js';

declare const IframeLightbox: any
@Directive({
  selector: '[appLightbox]'
})
export class LightboxDirective implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private el: ElementRef) { }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      new IframeLightbox(this.el.nativeElement);
    }
  }

}
