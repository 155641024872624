
class MovieList {
    description: string;
    name: string;
    items: Array<MovieItem>;
}

class MovieListUpdate extends MovieList {
    cred: string;
}

class MovieItem {
    id: number;
    media_type: string;
}

