<a
  href
  [routerLink]="'/list/' + listId"
  *ngIf="list && movieDetails"
  class="brightness-hover"
  title="Watchlist: {{ list.name }}"
>
  <div class="poster-wrapper d-inline-block">
    <ng-container *ngFor="let detail of movieDetails; let i = index">
      <img
        class="discover-poster"
        
        loading="lazy"
        src="https://image.tmdb.org/t/p/w185{{ detail.poster_path }}"
      />
    </ng-container>
  </div>
  <p class="text-weight-bold">{{ list.name }}</p>
</a>
