import {  Component,  Inject,  Input, OnDestroy, OnInit, PLATFORM_ID,  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MovieDbService } from 'src/app/services/movie-db.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ListEditModalComponent } from '../list-edit-modal/list-edit-modal.component';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { ShareModalComponent } from '../share-modal/share-modal.component';
import '../../models/MovieList'
import { SeoService } from 'src/app/services/seo.service';
import { MovieResult, TvResult } from 'moviedb-promise/dist/request-types';
import { PublishInfoModalComponent } from '../publish-info-modal/publish-info-modal.component';
import { Observable, Subscription } from 'rxjs';
import { CountryCode } from 'src/app/models/countryCodes';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-movie-list',
  templateUrl: './movie-list.component.html',
  styleUrls: ['./movie-list.component.scss']
})
export class MovieListComponent implements OnInit, OnDestroy {
  public backdrop_path: string;
  public query = '';

  public watchlistId: string;
  private token: string;
  public editEnabled = false;
  public editModalRef: BsModalRef;
  public shareInfoModalRef: BsModalRef;

  @Input() public list: MovieList;

  private movieListUpdate: MovieListUpdate;
  private routeSubscription: Subscription;

  public shareUrl: string;
  public editUrl: string;

  public deleteModalRef: BsModalRef;
  public gridView = false;

  public selectedRegion: CountryCode;
  regionObs: Observable<CountryCode>;
  public regionOptions: Array<string>;

  public isBrowser: boolean;

  constructor(  @Inject(PLATFORM_ID) private platformId: any,
    public mdb: MovieDbService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiServiceService,
    private modalService: BsModalService,
    private seoService: SeoService,
  ) { }


  ngOnInit(): void {
if(isPlatformBrowser(this.platformId)) {
  this.isBrowser = true;
}

    this.routeSubscription = this.route.params.subscribe(params => {
      if (params.id) {
        this.watchlistId = params.id;
        this.getList();
      }
      else if (!(this.list.items.length > 0)) {
        this.router.navigate([''])
      }
      if (params.token) {
        this.token = params.token;
        this.seoService.setNoFollow();
        this.authTest();
      }
    })

    this.regionOptions = Object.keys(CountryCode)
    this.regionObs = this.mdb.region$
  }

  ngOnDestroy() {
    this.seoService.resetMeta();
    this.routeSubscription?.unsubscribe();
  }

  updateRegion(selection: any) {
    this.mdb.region.next(selection);

  }

  authTest() {
    this.apiService.verify({
      "listId": this.watchlistId,
      "cred": this.token
    }).subscribe(
      res => {
        if (res.status === 'SUCCESS') {
          this.showEditFunctions();
        }
      }, error => {
        this.router.navigate(['/list/' + this.watchlistId]);
      })
  }

  getList() {
    this.apiService.getList(this.watchlistId).subscribe(success => {
      this.list = success;
      this.setMeta();
    }, _error => {
      this.router.navigate([''])
    })
  }

  setMeta() {
    this.seoService.setMeta("Watchlist: " + this.list.name, this.list.description, 'https://watch-lists.com/list/' + this.watchlistId, this.backdrop_path)
  }

  showEditFunctions() {
    this.editEnabled = true;
  }

  handleBackdropPath(path: string) {
    Promise.resolve().then(() => { this.backdrop_path = path; });
  }

  addItemToList(item: MovieResult | TvResult) {
    const newItem = {
      id: item.id ? item.id : 0,
      media_type: item.media_type
    }

    this.list.items.push(newItem);

    if (this.watchlistId) {

      this.movieListUpdate = this.apiService.movieListToUpdate(this.list, this.token);

      this.apiService.updateList(this.movieListUpdate, this.watchlistId).subscribe(success => { }, error => {
        console.log(error)
      });
    }
  }


  deleteList() {
    const cred = { cred: this.token }
    this.apiService.deleteList(cred, this.watchlistId).subscribe(success => {
      this.router.navigate(['']);

    }, error => {
      console.log(error)
    });
  }

  removeItem(itemObject: any) {
    const index = this.list.items.findIndex((el: any) => el.id.toString() === itemObject.id.toString());
    if (index !== -1) {
      this.list.items.splice(index, 1);
    }

    if (this.watchlistId) {
      this.movieListUpdate = this.apiService.movieListToUpdate(this.list, this.token);
      this.apiService.updateList(this.movieListUpdate, this.watchlistId).subscribe(s => {
        this.getList()
      }, error => {
        console.log(error)
      })
    }

  }

  public async createList() {
    this.apiService.createList(this.list).subscribe((s: any) => {
      this.router.navigate(['/list/' + s.id + '/' + s.cred])
    });
  }

  async openShareModal() {
    const initialState = {
      id: this.watchlistId,
      cred: this.token,
      title: this.list.name
    }
    this.editModalRef = this.modalService.show(ShareModalComponent, { initialState });
  }

  async showPublishModal() {
    this.shareInfoModalRef = this.modalService.show(PublishInfoModalComponent);
    await this.shareInfoModalRef.content.publishEvent.subscribe((p: any) => {
      this.createList();
      this.shareInfoModalRef.hide();
    })
  }

  async openEditModal() {
    const initialState = {
      title: this.list.name,
      description: this.list.description
    }
    this.editModalRef = this.modalService.show(ListEditModalComponent, { initialState });
    await this.editModalRef.content.saveEvent.subscribe((res: any) => {

      this.list.name = res.title;
      this.list.description = res.description;

      if (this.watchlistId) {
        this.movieListUpdate = this.apiService.movieListToUpdate(this.list, this.token);
        this.apiService.updateList(this.movieListUpdate, this.watchlistId).subscribe(s => {
          this.editModalRef.hide();
        }, error => {
          console.log(error)
        });

      } else {
        this.editModalRef.hide();
      }
    }, (error: any) => {
      console.log(error)
    })

    await this.editModalRef.content.deleteEvent.subscribe((_: any) => {
      this.editModalRef.hide();
      this.deleteList();
    })
  }

}
