<div class="android-banner" *ngIf="isAndroid && !hideAppBar">
  <div class="container-fluid">
    <div class="row">
      <div class="col px-2 py-1 d-flex align-items-center">
        <button class="btn btn-sm btn-text mr-1" (click)="hideBanner()">X</button>
        <div class="app-icon">
          <img src="../../../assets/play_icon.png" />
        </div>
        <div class="p-2 flex-grow-1">
          <p class="app-title m-0">Watchlists</p>
          <p class="app-claim m-0">FREE - In Google Play</p>
        </div>
        <button  class="btn btn-sm btn-primary" (click)="setLink()" >OPEN</button>
        <a href="#" id="refLink" target="_blank" class="d-none"></a>
      </div>
    </div>
  </div>
</div>
