<div class="container py-5" *ngIf="!list.items.length">
  <div class="row justify-content-center">
    <div class="col-lg-7">      
      <div class="d-flex justify-content-between">
        <h5 i18n>Create a new list</h5>
        <p><span i18n>Step</span> {{ step }}/2</p>
      </div>      
      <hr />
    </div>
  </div>

  <!-- movie search -->
  <div class="row justify-content-center" *ngIf="step === 1">
    <div class="col-lg-7">    
      <h3 i18n>Your first title on the list</h3>
      <app-movie-search (selected)="addItemToList($event)"></app-movie-search>
    </div>
  </div>

  <!-- create form -->
  <div class="row justify-content-center" *ngIf="step === 2">
    <div class="col-lg-7">
      <h3 i18n>Name your list</h3>
      <form [formGroup]="listEditForm">
        <div class="form-group">
          <label for="name" i18n>Title*</label>
          <input
            formControlName="name"
            id="name"
            class="form-control"
            type="text"
            required
            placeholder="e.g. All Marvel movies"
            i18n-placeholder
          />
        </div>
        <div class="form-group">
          <label for="description" ><span i18n>Description</span><i class="ml-1" i18n>(optional)</i></label>
          <textarea
            class="form-control"
            id="description"
            formControlName="description"
            rows="3"
            placeholder="e.g. My collection of all Marvel movies"
            i18n-placeholder
          ></textarea>          
          <small i18n>Max. 250 characters</small>
        </div>
      </form>
      <div class="row text-right">
        <div class="col">
          <button
            class="btn btn-primary"
            type="button"
            [disabled]="!listEditForm.valid"
            (click)="continue()" i18n
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>


</div>

<app-movie-list *ngIf="list.items.length" [list]="list"></app-movie-list>
