import { Injectable } from '@angular/core';
import { MovieDb } from 'moviedb-promise';
import { MovieResponse, SearchMultiResponse, ShowResponse, VideosResponse, WatchProviderResponse } from 'moviedb-promise/dist/request-types';
import { BehaviorSubject,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { CountryCode } from '../models/countryCodes';

@Injectable({
  providedIn: 'root'
})

export class MovieDbService {

  constructor(private http: HttpClient) { }
  moviedb = new MovieDb('4dc794b6f94d68c26f9fa2350524addb')
  private apiKey = '4dc794b6f94d68c26f9fa2350524addb';
  public region = new BehaviorSubject(CountryCode.DE);
  public region$ = this.region.asObservable();

  public language = new BehaviorSubject('de');
  public language$ = this.language.asObservable();


  getMovieObs(id: number): Observable<MovieResponse & { 'watch/providers': WatchProviderResponse } & { videos: VideosResponse }> {
    return this.http.get(`https://api.themoviedb.org/3/movie/${id}?api_key=${this.apiKey}&append_to_response=watch%2Fproviders,videos&language=${this.language.value}`) as
      Observable<MovieResponse & { 'watch/providers': WatchProviderResponse } & { videos: VideosResponse }>;
  }



  getShowObs(id: number): Observable<ShowResponse & { 'watch/providers': WatchProviderResponse } & { videos: VideosResponse }> {
    return this.http.get(`https://api.themoviedb.org/3/tv/${id}?api_key=${this.apiKey}&append_to_response=watch%2Fproviders,videos&language=${this.language.value}`) as
      Observable<MovieResponse & { 'watch/providers': WatchProviderResponse } & { videos: VideosResponse }>;

  }
  

  getMovieBasic(id: number): Observable<MovieResponse> {
    return this.http.get(`https://api.themoviedb.org/3/movie/${id}?api_key=${this.apiKey}&language=${this.language.value}`) as
      Observable<MovieResponse>;
  }

  getShowBasic(id: number): Observable<ShowResponse> {
    return this.http.get(`https://api.themoviedb.org/3/tv/${id}?api_key=${this.apiKey}&language=${this.language.value}`) as
      Observable<MovieResponse>;
  }

  searchTitle(query: string): Promise<SearchMultiResponse> {
    return this.moviedb.searchMulti({ query: query, language: this.language.value });
  }

  getMovieDetail(id: number): Promise<MovieResponse & { 'watch/providers': WatchProviderResponse } & { videos: VideosResponse }> {
    return this.moviedb.movieInfo({ id: id, append_to_response: 'watch/providers,videos', language: this.language.value }) as
      Promise<MovieResponse & { 'watch/providers': WatchProviderResponse } & { videos: VideosResponse }>;
  }

  getTvShowDetail(id: number): Promise<ShowResponse & { 'watch/providers': WatchProviderResponse } & { videos: VideosResponse }> {
    return this.moviedb.tvInfo({ id: id, append_to_response: 'watch/providers,videos', language: this.language.value }) as
      Promise<ShowResponse & { 'watch/providers': WatchProviderResponse } & { videos: VideosResponse }>;
  }

  getFullPosterPath(posterPath: string, width = '185') {
    // "w92",
    // "w154",
    // "w185",
    // "w342",
    // "w500",
    // "w780",
    // "original"

    return "https://image.tmdb.org/t/p/w" + width + posterPath;
  }

  // getLanguages(): Promise<Array<Language>>{
  //   return this.moviedb.languages();
  // }

}