import { Component, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-list-discover-wrapper',
  templateUrl: './list-discover-wrapper.component.html',
  styleUrls: ['./list-discover-wrapper.component.scss']
})
export class ListDiscoverWrapperComponent implements OnInit {

  public list1 = 98;
  public list2 = 42;
  public list3 = 49;

  constructor() { }

  ngOnInit(): void {

    if (environment.production) {
      this.list1 = 42;
      this.list2 = 49;
      this.list3 = 84;
    }
  }

}
