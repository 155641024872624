import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-new-list',
  templateUrl: './new-list.component.html',
  styleUrls: ['./new-list.component.scss']
})
export class NewListComponent implements OnInit {
  public step = 1;

  public list = <MovieList>{
    name: '',
    description: '',
    items: [],
  }
  public newItem: any;

  public listEditForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
  });

  constructor(private seoService:SeoService) { }

  ngOnInit(): void {
    this.seoService.setMeta("Watchlists: Create a new list","Create a new watchlist with your favourite movies and series.")
  }
  continue() {
    // this.step = 2
    this.list.name = this.listEditForm.get('name')?.value
    this.list.description = this.listEditForm.get('description')?.value

    this.list.items.push(this.newItem);
  }


  addItemToList(title: MovieItem) {
    this.newItem = {
      id: title.id,
      media_type: title.media_type
    }
    this.step = 2
    // this.list.items.push(this.newItem);
  }


}
