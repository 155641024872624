<div class="modal-header">
  <h4 class="modal-title pull-left" i18n>Edit list</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="listEditForm">
    <div class="form-group">
      <label for="name" i18n>Title</label>
      <input
        formControlName="name"
        id="name"
        class="form-control"
        type="text"
        placeholder="Name"
      />
    </div>
    <div class="form-group">
      <label for="description" i18n>Description</label>
      <textarea
        class="form-control"
        id="description"
        formControlName="description"
        rows="3"
      ></textarea>
      <small i18n>Max. 250 characters</small>
    </div>
  </form>

  <button
    class="btn btn-sm btn-danger text-white ml-auto"
    (click)="showDeleteModal(deleteModal)" i18n
  >
    Delete this list 
  </button>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()" i18n>
    Close
  </button>
  <button type="button" class="btn btn-success" [disabled]="!listEditForm.valid" (click)="save()" i18n>Save</button>

  <ng-template #deleteModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left" i18n>Delete watchlist</h4>
      <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="deleteModalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body" i18n>
      Are you sure you want to delete your watchlist? This cannot be undone.
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="deleteModalRef?.hide()" i18n
      >
        Cancel
      </button>
      <button type="button" class="btn btn-danger" (click)="delete()" i18n>
        Delete list
      </button>
    </div>
  </ng-template>
</div>
