<!-- Body -->
<div
  *ngIf="list"
  class="head"
  [ngStyle]="{
    'background-image': backdrop_path
      ? 'url(' + 'https://image.tmdb.org/t/p/w1280' + backdrop_path + ')'
      : ''
  }"
></div>

<div class="container-fluid margin-backdrop mb-3" *ngIf="list">
  <div class="row">
    <div class="col">
      <div class="list-wrapper">
        <div class="row">
          <div class="col-lg-12 text-white">
            <div class="row">
              <div class="col text-left">
                <button
                  *ngIf="editEnabled || !watchlistId"
                  class="btn btn-sm btn-link text-white ml-auto"
                  (click)="openEditModal()"
                >
                  <fa-icon [icon]="['fas', 'pen']"></fa-icon
                  ><span class="ml-1" i18n>Edit</span>
                </button>
              </div>
              <div class="col text-right">
                <button
                  *ngIf="watchlistId"
                  class="btn btn-sm btn-link text-white"
                  (click)="openShareModal()"
                >
                  <fa-icon [icon]="['fas', 'share-square']"></fa-icon>
                  <span class="ml-1" i18n>Share</span>
                </button>

                <button
                  *ngIf="!watchlistId"
                  class="btn btn-xl btn-success"
                  (click)="showPublishModal()"
                >
                  <fa-icon [icon]="['far', 'paper-plane']"></fa-icon>
                  <span class="ml-1" i18n>Publish list</span>
                </button>
              </div>
            </div>

            <h1 class="text-truncate" style="line-height: normal">
              {{ list.name }}
            </h1>
            <p *ngIf="list.description">{{ list.description }}</p>

            <app-movie-search
              *ngIf="editEnabled || !watchlistId"
              (selected)="addItemToList($event)"
            ></app-movie-search>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-between align-content-center">
            <div class="position-relative" dropdown>
              <button
                id="region-button"
                dropdownToggle
                type="button"
                class="btn btn-secondary btn-sm dropdown-toggle"
                aria-controls="dropdown-basic"
              >
                Region: {{ regionObs | async }} <span class="caret"></span>
              </button>
              <ul
                id="region-dropdown"
                *dropdownMenu
                class="dropdown-menu region"
                role="menu"
                aria-labelledby="basic-link"
              >
                <li *ngFor="let choice of regionOptions">
                  <button class="dropdown-item" (click)="updateRegion(choice)">
                    {{ choice }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="btn-group">
              <label
                class="btn btn-secondary btn-sm"
                [class.active]="!gridView"
                (click)="gridView = false"
                btnCheckbox
                tabindex="0"
                role="button"
                ><fa-icon [icon]="['fas', 'th-list']"></fa-icon
              ></label>
              <label
                class="btn btn-secondary btn-sm"
                [class.active]="gridView"
                (click)="gridView = true"
                btnCheckbox
                tabindex="0"
                role="button"
                ><fa-icon [icon]="['fas', 'th']"></fa-icon
              ></label>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <ng-container
            *ngFor="
              let item of list?.items;
              let first = first;
              let index = index
            "
          >
            <ng-container *ngIf="isBrowser || index < 10">
              <app-movie-item
                [showRemove]="list.items.length > 1"
                (backdrop_path)="first ? handleBackdropPath($event) : null"
                [firstElement]="first"
                [id]="item.id"
                [type]="item.media_type"
                [editMode]="editEnabled || !watchlistId"
                (removeEvent)="removeItem($event)"
                [gridView]="gridView"
              ></app-movie-item>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
