import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { MovieDbService } from 'src/app/services/movie-db.service';

@Component({
  selector: 'app-list-discover-item',
  templateUrl: './list-discover-item.component.html',
  styleUrls: ['./list-discover-item.component.scss']
})
export class ListDiscoverItemComponent implements OnInit {

  @Input() listId: number;

  public list: MovieList;
  public languageSubscription: Subscription;
  public movieDetails: Array<any> = [];

  constructor(
    public router: Router,
    private apiService: ApiServiceService,
    private mdb: MovieDbService) { }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.apiService.getList(this.listId).subscribe(success => {
      this.list = success;

      this.languageSubscription = this.mdb.language$.subscribe(r =>
        this.getMovieItemDetails())
    })


  }

  getMovieItemDetails() {

    for (var i = 0; i < 3; i++) {
      if (this.list.items[i].media_type === 'movie') {
        this.mdb.getMovieBasic(this.list.items[i].id).subscribe(res => {
          this.movieDetails.push(res);
        })
      } else {
        this.mdb.getShowBasic(this.list.items[i].id).subscribe(res => {
          this.movieDetails.push(res);
        })

      }

    }
  }


}
