import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { MovieDbService } from './services/movie-db.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {


  constructor(
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DOCUMENT) private dom: Document,
    private mdb: MovieDbService,
    private metaSvc: Meta
  ) {
  }


  ngOnInit() {
    this.metaSvc.addTag({ name: 'apple-itunes-app', content: 'app-id=1604193314, app-argument=' + this.dom.location.href });
    this.mdb.language.next(this.locale);
  }
}
